import useAuth from "./hooks/useAuth";
import { useEffect, useState } from "react";

import PhonemeChart from "./PhonemeChart";
import CategoryDropdownDynamic from "./CategoryDropdownDynamic";



const UserPhonemesSnapshot = ({language, date}) => {
    const {auth, setAuth} = useAuth();
    const [stats, setStats] = useState();
    const [representation, setRepresentation] = useState('Snapshot');
    const [dynamic, setDynamic] = useState()

    useEffect(()=>{ 
        if (language){
        getMyStats(); 
       }},[language,date])
    
     
    const getMyStats = async () => {
        const token = localStorage.getItem("token");
        
       
        if (date ==='All records')
            {            

            fetch(`${process.env.REACT_APP_USER_API_URL}/analysis/${language}/phonemes` , {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                }
            }).then(response => {
                if (response.ok) {
                    return response.json();
                }
                if (response.status === 401) {
                    localStorage.removeItem("token");
                    setAuth({});
                }
            }).then(json => {
                if (json) {
                
                    setStats(json);}
                })
            }
        

        else if (date ==='Select date')
        {
            console.log("no date")
        }            
        else 
        {
            fetch(`${process.env.REACT_APP_USER_API_URL}/analysis/${language}/phonemes?date=${date}` , {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                }
            }).then(response => {
                if (response.ok) {
                    return response.json();
                }
                if (response.status === 401) {
                    localStorage.removeItem("token");
                    setAuth({});
                }
            }).then(json => {
                if (json) {
                    
                    setStats(json);}
                })
        }



     
    }
    

    return (
    
        stats ?
        <div className="flex flex-col"><p className="text-xl text-blue-600 font-bold mx-auto mt-5">Phonemes stats</p>


           
            {stats.hasOwnProperty("phonemes")&&stats.phonemes.map((key, i)=> {
                let data = [{ name: "Accuracy: 80-100%", value: stats["colored"][key]["green"]}, {name: "Accuracy: 40-80%", value:  stats["colored"][key]["yellow"]},{name: "Accuracy: 0-40%", value: stats["colored"][key]["red"]}]
                let data_start = null;
                let data_end = null;
                let data_inside = null;
                let total = stats["colored"][key]["total"]
                
                let total_inside = 0
                let total_start = 0
                let total_end = 0

                let sample = stats["colored"][key]["sample"]
                let sample_inside = ""
                let sample_start = ""
                let sample_end = ""

              
                
                
                if (stats["colored"].hasOwnProperty(key+"_start")){
                    console.log({name: "Accuracy: 80-100%",value: stats["colored"][key+'_start']["green"]}, {name: "Accuracy: 40-80%", value:  stats["colored"][key+'_start']["yellow"]},{name: "Accuracy: 0-40%", value: stats["colored"][key+'_start']["red"]})
                    data_start = [{name: "Accuracy: 80-100%",value: stats["colored"][key+'_start']["green"]}, {name: "Accuracy: 40-80%", value:  stats["colored"][key+'_start']["yellow"]},{name: "Accuracy: 0-40%", value: stats["colored"][key+'_start']["red"]}]
                    total_start = stats["colored"][key+'_start']["total"]
                    sample_start = stats["colored"][key+'_start']["sample"]
                }

                if (stats["colored"].hasOwnProperty(key+"_inside")){
                    data_inside = [
                    {name: "Accuracy: 80-100%",value: stats["colored"][key+'_inside']["green"]}, 
                    {name: "Accuracy: 40-80%", value:  stats["colored"][key+'_inside']["yellow"]},
                    {name: "Accuracy: 0-40%", value: stats["colored"][key+'_inside']["red"]}]
                    total_inside = stats["colored"][key+'_inside']["total"]
                    sample_inside = stats["colored"][key+'_inside']["sample"]
                }
                if (stats["colored"].hasOwnProperty(key+"_end")){
                    data_end = [
                    {name: "Accuracy: 80-100%",value: stats["colored"][key+'_end']["green"]}, 
                    {name: "Accuracy: 40-80%", value:  stats["colored"][key+'_end']["yellow"]},
                    {name: "Accuracy: 0-40%", value: stats["colored"][key+'_end']["red"]}]
                    total_end = stats["colored"][key+'_end']["total"]
                    sample_end = stats["colored"][key+'_end']["sample"]
                }
               
                return (
                    
               <div  key={i+key} className="scale-75 md:scale-100">  
               <PhonemeChart key={key+i+language} data={data} data_start={data_start} count_end={total_end} count_inside={total_inside} count_start={total_start} data_inside={data_inside} data_end={data_end} count={total} phoneme={key} sample={sample} sample_end={sample_end} sample_start={sample_start} sample_inside={sample_inside} />
                </div>
                )
            })}
        </div>
        : <div>loading...</div>
            
        
        
    )
}

export default UserPhonemesSnapshot;
