import useAuth from "./hooks/useAuth";
import { useEffect, useState } from "react";
import StackedBarChart from "./StackedBarChart";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';





const UserPhonemesDynamic = ({language, date}) => {
    const {auth, setAuth} = useAuth();
    const [dynamic, setDynamic] = useState()

    useEffect(()=>{ 
        if (language){
         getMyDynamics()
       }},[language,date])
    
 

    const getMyDynamics = async () => {
        const token = localStorage.getItem("token");
        
       
                 

            fetch(`${process.env.REACT_APP_USER_API_URL}/analysis/${language}/phonemes/dynamics` , {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                }
            }).then(response => {
                if (response.ok) {
                    return response.json();
                }
                if (response.status === 401) {
                    localStorage.removeItem("token");
                    setAuth({});
                }
            }).then(json => {
                if (json) {
                
                    setDynamic(json);}
                })
            }
        

      



     
    
    


    
  

    return (
        
      
        dynamic? <div className="flex flex-col"><p className="text-xl text-blue-600 font-bold mx-auto mt-5">Phonemes stats</p>



        
             <div className="flex flex-wrap gap-5">
                
                {console.log(dynamic, "fullstats")}
                {(dynamic.phonemes.length>0)&& 
                  
                <div className="mx-auto"> 
                
                  <BarChart
                  layout="vertical"
                  
                  width={400}
                  height={1000}
                  data={dynamic.weighted_phonemes}
                  margin={{
                      top: 20,
                      right: 30,
                      left: 20,
                      bottom: 5,
                  }}
                  >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis type="number" />
                  <YAxis dataKey="phoneme" type="category" interval={0}/>
                  <Tooltip />
                  <Legend layout="horizontal" verticalAlign="top" align="top" />
                  
                  <Bar name="Averaged accuracy for each phoneme over all records: difficulty from difficult to easy" dataKey="weight" stackId="a" fill="#4682b4" />
                  
              </BarChart>
                </div>
                }
               {
                dynamic.phonemes.map((key, i)=> {
                let data_inside = null
                let data_end =null 
                let data_start = null
                let sample_inside = ""
                let sample_start = ""
                let sample_end = ""
                let sample = ""
                let data_av = null
                let data_av_start = null
                let data_av_end = null
                let data_av_inside = null
                

                if (dynamic.full.hasOwnProperty(key)){
                    data_av = dynamic.full[key]
                }
                if (dynamic.dynamic.hasOwnProperty(key)){  
                
                
                sample = dynamic.dynamic[key][0][0].sample
                
                if (dynamic.full.hasOwnProperty(key+"_start")){
                   
                    data_av_start = dynamic.full[key+'_start']
                   
                }
                if (dynamic.full.hasOwnProperty(key+"_end")){
                   
                    data_av_end = dynamic.full[key+'_end']
                   
                }
                
                if (dynamic.full.hasOwnProperty(key+"_inside")){
                   
                    data_av_inside = dynamic.full[key+'_inside']
                   
                }
                
                
                if (dynamic.dynamic.hasOwnProperty(key+"_start")){
                   
                    data_start = dynamic.dynamic[key+'_start']
                    sample_start = dynamic.dynamic[key+'_start'][0][0].sample
                }


                if (dynamic.dynamic.hasOwnProperty(key+"_inside")){
                    data_inside = dynamic.dynamic[key+'_inside']
                    sample_inside = dynamic.dynamic[key+'_inside'][0][0].sample
                }
                if (dynamic.dynamic.hasOwnProperty(key+"_end")){
                    data_end = dynamic.dynamic[key+'_end']
                    sample_end = dynamic.dynamic[key+'_end'][0][0].sample
                }
                return (
               <div key={key+i}>
                   
              <StackedBarChart  phoneme={key} 
                                data_av={data_av} 
                                data_av_end={data_av_end} 
                                data_av_start={data_av_start} 
                                data_av_inside={data_av_inside} 
                                data={dynamic.dynamic[key]} 
                                data_end={data_end} 
                                data_start={data_start} 
                                data_inside={data_inside} 
                                sample={sample} 
                                sample_end={sample_end} 
                                sample_start={sample_start} 
                                sample_inside={sample_inside}

                />
                </div>
                )}
            })}
            
            </div>
            






        </div>    
        : <div>loading...</div>
      
        

       
       )
        
        
    
}

export default UserPhonemesDynamic;
