import React, { useEffect } from 'react';
import { useState } from 'react';
import CategoryDropdownPosition from './CategoryDropdownPosition';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';




const StackedBarChart = ({phoneme, data_av, data_av_start, data_av_end, data_av_inside, data, data_start, data_inside, data_end, sample_end, sample, sample_inside, sample_start})=> {
        const [position, setPosition] = useState()
        const positions = ["All positions"]
        const [outdata, setOutdata]= useState(data)
        const [outdataav, setOutdataav] = useState(data_av)
        const [outsample, setSample] = useState(sample)

        if (data_start){
                positions.push("start")}
        if (data_end){
                positions.push("end")}
        if (data_inside){
                positions.push("inside")}
        

        
        
        useEffect(()=>{
                if (position === 'start'){
                        setOutdata(data_start)
                        setOutdataav(data_av_start)
                        setSample(sample_start) 
                        
                }
                else if (position =="end"){
                        setOutdata(data_end)
                        setOutdataav(data_av_end)
                        setSample(sample_end)
                }
                else if (position =="inside"){
                        setOutdata(data_inside)
                        setOutdataav(data_av_inside)
                        setSample(sample_inside)
                }
                else{
                        setOutdata(data)
                        setOutdataav(data_av)
                        setSample(sample)
                }

                
        },[position])
        return(
           
                <div className='border-2 p-5 bg-gray-50 rounded-xl'>
                <div className='flex flex-col'>
                        <div className='flex flex-row mx-auto items-center'>
                                <p className='max-auto text-3xl text-blue-600 font-bold'>
                                        {phoneme}</p> 
                <CategoryDropdownPosition 
                selectHandler={e => setPosition(e.target.value)} 
                elValue={position}
                positions={positions} />  </div>  
                 <div className='flex flex-row gap-5 items-center mx-auto border-2 bg-white p-1 rounded-xl '><p className='text-green-500 text-md'>Sample: </p> <p className='text-2xl text-blue-600 font-bold'>{outsample}</p></div>

                       
                 </div>
                 <div className="scale-75 md:scale-100 border-2">  
                
                <div className='flex flex-col'>
                <p className='text-sm text-blue-400'>Averaged accuracy</p>
                 <BarChart
                    width={300}
                    height={200}
                    data={outdataav}
                    margin={{
                        top: 20,
                        right: 30,
                        left: 20,
                        bottom: 5,
                    }}
                    >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="date" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    
                    <Bar name="Daily averages" dataKey="average" stackId="a" fill="#4682b4 " />
                    
                </BarChart>
                </div>
                <div className='flex flex-col'>
                <p className='text-sm text-blue-400'>Distribution into three groups</p>  
                    <BarChart
                    width={300}
                    height={200}
                    data={outdata[1]}
                    margin={{
                        top: 20,
                        right: 30,
                        left: 20,
                        bottom: 5,
                    }}
                    >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="date" />
                    <YAxis />
                    <Tooltip />
                    <Legend layout="horizontal" verticalAlign="top" align="top"/>
                    <Bar name="80-100%" dataKey="green" stackId="a" fill="#00C49F" />
                    <Bar name="40-80%" dataKey="yellow" stackId="a" fill="#FFBB28" />
                    <Bar name="0-40%" dataKey="red" stackId="a" fill="#FF8042" />
                    
                    </BarChart>
                </div>
                <div className='flex flex-col'>
                <p className='text-sm text-blue-400'>Normalized ratio</p> 
                    <BarChart
                    width={300}
                    height={200}
                    data={outdata[0]}
                    margin={{
                        top: 20,
                        right: 30,
                        left: 20,
                        bottom: 5,
                    }}
                    >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="date" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    
                    <Bar name="80-100%" dataKey="green" stackId="a" fill="#00C49F" />
                    <Bar name="40-80%" dataKey="yellow" stackId="a" fill="#FFBB28" />
                    <Bar name="40-80%" dataKey="red" stackId="a" fill="#FF8042" />
                    </BarChart>

                </div>


                
                </div>
                
              
              
                </div>
        
        
        )
            

        
       
    }

export default StackedBarChart;
