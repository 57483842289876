import React from "react";
import Select from 'react-select'
const CategoryDropdownDynamic = ({ selectHandler, elValue}) => {
  const  types =  [{'value': "Dynamic", 'label': 'Dynamic'},
    {'value': "Snapshot", 'label': 'Snapshot'}];
  
  return (
    
    <Select 
    onChange={selectHandler}
    options={types}
    defaultValue={types[1]}
/>



    
    
    
    
    
   
  );
};


export default CategoryDropdownDynamic;
