import useAuth from "./hooks/useAuth";
import { useEffect, useState } from "react";
import CategoryDropdownDynamic from "./CategoryDropdownDynamic";
import UserPhonemesDynamic from "./UserPhonemesDynamic";
import UserPhonemesSnapshot from "./UserPhonemesSnapshot";



const UserPhonemes = ({language, date}) => {
    const {auth, setAuth} = useAuth();
    const [stats, setStats] = useState();
    const [representation, setRepresentation] = useState('Snapshot');
    const [dynamic, setDynamic] = useState()

   


    return (
    
        <div className="flex flex-col md:flex-wrap gap-5 m-5">
            <h1>Presentation:</h1>
             <CategoryDropdownDynamic selectHandler={e => setRepresentation(e.value)}/> 

            
        
            <div className="flex flex-col">
                
            
                {(representation === 'Snapshot') ?
                        <UserPhonemesSnapshot language={language} date={date}/>
                
                    
                    : 
                        <UserPhonemesDynamic language={language} date={date}/>   
                    }
            </div>
        </div>
        
        

    )
        
        
    
}

export default UserPhonemes;
