import useAuth from "./hooks/useAuth";
import { useState } from "react";
import CategoryDropdownLanguages from "./CategoryDropdownLanguages";
import CategoryDropdownDates from "./CategoryDropdownDates";
import UserSentences from "./UserSentences";
import UserWords from "./UserWords";
import UserPhonemes from "./UserPhonemes";
import Select from 'react-select'

const UserStats = () => {
    const {auth, setAuth} = useAuth();
    const  stats =  [{'value': "Sentences", 'label': 'Sentences'},
      {'value': "Words", 'label': 'Words'} ,{'value': "Phonemes", 'label': 'Phonemes'}];
    const [language, setLanguage] = useState()
    const [date, setDate] = useState('Select date')
    const [type, setType] = useState(stats[2].value)
  
   
   

    

    

    return (
    
       <div className="flex flex-col ">
        
    
        <div className="flex flex-wrap gap-2 mx-auto items-center">
          <div className="flex flex-col">  <h1>Language:</h1> 
           <CategoryDropdownLanguages selectHandler={e => setLanguage(e.value)}  />
            </div>
            <div className="flex flex-col"> <h1>Records:</h1>
          <CategoryDropdownDates language={language} selectHandler={e => setDate(e.value)}  />
            </div>
            <div className="flex flex-col">
            <h1>Category:</h1>
            <Select 
              onChange={e => {setType(e.value); }}
              options={stats}
              defaultValue={stats[2]}/>
          </div>
       </div>

        { (language && (date!=='Select date')) ? 
        <>

        {(type === 'Words') && <UserWords language={language} date={date}/>}


        
       {(type === 'Sentences') && <UserSentences language={language} date={date}/>}

       {(type === 'Phonemes') && <UserPhonemes language={language} date={date}/>}

        </>
        :
        <div className="p-10 mx-auto text-xl text-blue-400">Select parameters</div>
        }
       
        
        
        </div>
    )
}

export default UserStats;
