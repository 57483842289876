import React from "react";
import Select from 'react-select'

const CategoryDropdownScores = ({ selectHandler}) => {
  const  types =  [{"label":"AccuracyScore", "value" : "AccuracyScore"}, 
                    {"label": "FluencyScore", "value": "FluencyScore"},
                    {"label":"CompletenessScore",  "value":"CompletenessScore"}, 
                    {"label": "PronScore", "value":"PronScore"}];
  
  return (
    
    <Select  
    onChange={selectHandler}
    options={types}
    defaultValue={types[0]} 
    />



    
    
    
    
    
   
  );
};


export default CategoryDropdownScores;
