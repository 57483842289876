import {useState, useEffect, useRef} from 'react'
import useAuth from '../../Users/hooks/useAuth';
import AccessInterface from '../../Users/AccessInterface';
import UserProfile from '../../Users/UserProfile';
import BookCase from '../../Books/BookCase';
import Card from './Card';
import Audiobook from './Audiobook';
import Videobook from './Videobook';
import Author from './Author';




const Collection = ({language,itemHandler}) => {
    console.log(language, "language")
    const [authors, setAuthors]=useState([])
    const [subject, setSubject] = useState()
   
    
    const itemsRef = useRef(null);



    const getAuthors = async (language) => {
        const token = localStorage.getItem("token");
            
        const response = await fetch(`${process.env.REACT_APP_USER_API_URL}/subjects/list-authors?language=${language}`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        },
        /* body: JSON.stringify(outdata),*/
        });
        
        // if the login is successful - get the token and then get the remaining data from the /me route
        if (response.ok) {
            const json = await response.json();
            
            setAuthors(json)
        
            };
    }

  

    useEffect(()=>{getAuthors(language);},[])


        

 



    

 


 

   




  

 
 

    return (
        
   
                        
                        <div className="flex flex-col">
                            
                                                                         
                           
                                    <div className='flex flex-col md:flex-row gap-5  mx-auto mt-5 '>
                                       {authors &&
                                       authors.map((item, i)=>{
                                        
                                        return (

                                            <Author key={item+i} author={item} language={language} itemHandler={(state) => itemHandler(state)}/>
                                        )


                                       }) }
                                    </div>                
                                </div>
                         
    )
}

export default Collection;