import useAuth from "./hooks/useAuth";
import { useEffect, useState } from "react";
import CategoryDropdownScores from "./CategoryDropdownScores";
import CategoryDropdownDynamic from "./CategoryDropdownDynamic";
import UserSentencesSnapshot from "./UserSentencesSnapshot";
import UserSentencesDynamic from "./UserSentencesDynamic";




const UserSentences = ({language, date}) => {
    const {auth, setAuth} = useAuth();
    
    const [representation, setRepresentation] = useState('Snapshot');
    
   
   
    
        

    
    

    return (
    
       <div className="flex flex-col">
       <div className="mx-auto flex flex-row items-center gap-2">
        <p className="text-sm text-blue-400 my-5">Presentation:</p>
             <CategoryDropdownDynamic selectHandler={e => setRepresentation(e.value)}/> 

            </div>
        <div>
        {(representation === 'Snapshot') ? <UserSentencesSnapshot language={language} date={date}/>
       
        : <UserSentencesDynamic language={language} date={date}/>
        }
        </div>
       
        </div>
        
     
        
       )
        
        
    
}

export default UserSentences;
