import React from "react";
import Select from 'react-select'
import { useEffect, useState } from "react";

import useSWR from "swr";
const fetcher = (...args) => fetch(...args).then((res) => res.json());

const CategoryDropdownLanguages = ({ selectHandler}) => {
  const [options, setOptions] = useState()
  const { data, error } = useSWR(
    `${process.env.REACT_APP_USER_API_URL}/analysis/list_languages`,
    fetcher,   {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false
    }
    
  ); 
  useEffect(()=>{
    var op = []
    if (data){
      data.map((item, i) =>{
        op.push({'value':item, 'label': item})
      setOptions(op)
      })

    }
  },[data])
  if (error) return ( 
   
                 
                <div> analysis is not available</div>
                
                
              )

  if (!data) return ( 
   
                 <div>Loading list...</div>
                
   
              )


  return (
    options&&<Select className=""
      onChange={selectHandler}
      options={options}
      
    />
     
    
    
  );
};


export default CategoryDropdownLanguages;
