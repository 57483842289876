import useAuth from "./hooks/useAuth";
import { useEffect, useState } from "react";
import CategoryDropdownScores from "./CategoryDropdownScores";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import Select from 'react-select';




const UserSentencesDynamic = ({language, date}) => {
    const {auth, setAuth} = useAuth();
    const [stats, setStats] = useState();
    const [scoreType, setScoreType] = useState('AccuracyScore')
    const [lowlimit, setLowlimit] = useState()
    
    
   
    useEffect(()=>{ getMyStats()},[language,date, scoreType, lowlimit])
    
    const snap = new Audio()
  
    const Play = (audio) =>{
    if (audio){
    snap.src = `${process.env.REACT_APP_USER_API_URL}/`+audio
    snap.load()
    snap.play()
              
    }       
    }   
        

    
    const getMyStats = async () => {
        const token = localStorage.getItem("token");
            let query=`${process.env.REACT_APP_USER_API_URL}/analysis/${language}/${scoreType}/sentences/dynamics`
            if (lowlimit){
            query=`${process.env.REACT_APP_USER_API_URL}/analysis/${language}/${scoreType}/sentences/dynamics?lowerlimit=${lowlimit}`
            }
            
            fetch(query , {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                }
            }).then(response => {
                if (response.ok) {
                    return response.json();
                }
                if (response.status === 401) {
                    localStorage.removeItem("token");
                    setAuth({});
                }
            }).then(json => {
                if (json) {
                
                    setStats(json);}
                })
            }
        

            
        



     
    
    

    return (
    
       <>
       
       
       {(stats) ?
            


            
            <div className="flex flex-col mx-auto gap-y-5 gap-x-5 md:p-10">

                    <div className="flex flex-col md:flex-row mx-auto gap-2">
                    <div className="flex flex-col">    
                    <p className="text-sm text-blue-600 text-left">Arrange by score:</p> 
                    <CategoryDropdownScores selectHandler={e => setScoreType(e.value)} elValue={scoreType}/> 
                    </div>
                    <div className="flex flex-col">
                    <p className="text-sm text-blue-600 text-left">Show records above threashold </p>
                    <Select 
                        onChange={e => {setLowlimit(e.value) }}
                        options={stats.counts}
                    />
                    </div>
                    </div>
           
               
               <div className="scale-75 md:scale-100 border-2">  
               
                
                <div>
                
                <BarChart
                width={500}
                height={300}
                data={stats['data']}
                margin={{
                    top: 20,
                    right: 30,
                    left: 20,
                    bottom: 5,
                }}
                >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="date" />
                <YAxis />
                <Tooltip />
                <Legend layout="horizontal" verticalAlign="top" align="top"/>
                <Bar name="AI-assessed as above 80%" dataKey="green" stackId="a" fill="#00C49F" />
                <Bar name="AI-assessed as in 40%-80%" dataKey="yellow" stackId="a" fill="#FFBB28" />
                <Bar name="AI-assessed as in 0%-40%" dataKey="red" stackId="a" fill="#FF8042" />
                </BarChart>
                </div>
                <div className="flex flex-col">
                    <p>Normalized</p>

                <BarChart
                width={500}
                height={300}
                data={stats['normdata']}
                margin={{
                    top: 20,
                    right: 30,
                    left: 20,
                    bottom: 5,
                }}
                >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="date" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey="green" stackId="a" fill="#00C49F" />
                <Bar dataKey="yellow" stackId="a" fill="#FFBB28" />
                <Bar dataKey="red" stackId="a" fill="#FF8042" />
                
                </BarChart>
                </div>
                
                <div className="flex flex-col">
                    <p>Average</p>

                <BarChart
                width={500}
                height={300}
                data={stats['average']}
                margin={{
                    top: 20,
                    right: 30,
                    left: 20,
                    bottom: 5,
                }}
                >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="date" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey="average" stackId="a" fill="#4682b4" />
                
                
                </BarChart>
                </div>
                
            
            </div>

            </div>
        : <div>loading...</div>}
                  
       
        </>
        
     
        
       )
        
        
    
}

export default UserSentencesDynamic;
